import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { Routes, Route } from 'react-router-dom'

import { useAuth } from '../../hooks'
import { Header, VendorWelcome } from '../../views'
import { Steps, Logo, IntlText } from '../../components'

import { OpeningHours } from './OpeningHours'
import { Delivery } from './Delivery'
import { Payment } from './Payment'
import { HelpCenter } from './HelpCenter'
import { Help } from './Help'
import { Terms } from './Terms'

const steps = [
    {label: <IntlText group='wizard' id='welcome' />, url: ''}, 
    {label: <IntlText group='wizard' id='terms' />, url: 'terms'}, 
    {label: <IntlText group='wizard' id='opening-hours' />, url: 'opening-hours'},
    {label: <IntlText group='wizard' id='payment' />, url: 'payment'},
    {label: <IntlText group='wizard' id='delivery' />, url: 'delivery'}
]

export function VendorSetupWizard() {

    const step = useLocation()
    const { user, loading, error} = useAuth()

    if (loading || error) return null

    return(
        <div id="app">
            <div id="main">
                <Header>
                    <Logo />
                </Header>
                <div className="row wrapped">
                    <Steps steps={steps} active={step?.pathname?.replaceAll("/", '')} />
                </div>
                <div className="row wrapped">
                    <Routes>
                        <Route path="" element={<VendorWelcome vendor={user} />} />
                        <Route path="terms" element={<Terms vendor={user} />} />
                        <Route path="opening-hours" element={<OpeningHours vendor={user} />} />
                        <Route path="payment" element={<Payment vendor={user} />} />
                        <Route path="delivery" element={<Delivery vendor={user} />} />
                        <Route path={"help"} >
                            <Route path="" element={<HelpCenter />} />
                            <Route path=":id" element={<Help />} />
                        </Route>                          
                        <Route path="*" element={<Navigate to="" />} />
                    </Routes>      
                </div>
            </div>
        </div>    
    )
}