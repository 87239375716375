import React from 'react'
import FontAwesome from 'react-fontawesome'
import { NavLink } from 'react-router-dom'

import { Loader } from '../'

import './style.css'

export function Link({className, theme, loading, button, children, icon, styled, external, ...rest}){

  function showIcon(){
    switch (typeof icon) {
      case "string":
        return <div className="icon"><FontAwesome name={icon} /></div>
      default:
        return <div className="icon">{icon}</div>
    }
  }

  if (external) return (
    <a href={rest.to} target="_blank" rel="noreferrer" className={`link ${className || ""} ${button ? "button" : ""} ${icon ? "icon" : ""}`} {...rest}>
      {icon && showIcon()}
      {children}
    </a>
)    

  return(
    <NavLink className={`link ${className || ""} ${styled ? "styled" : ""} ${button ? "button" : ""} ${icon ? "icon" : ""}`} {...rest}>
      {icon && showIcon()}
      <span>{loading && <Loader />}{children}</span>
    </NavLink>
  )

}
