import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'

import { EDIT_VENDOR_MUTATION } from '../../../graphql'
import { toast } from '../../../helpers'
import { IntlContext } from '../../../contexts'
import { LabeledCheckbox, HourPicker, IntlValue, IntlText } from '../../../components'

import './style.css'

const days = [{en: "Monday", es: "Lunes"}, {en: "Tuesday", es: "Martes"}, {en: "Wednesday", es: "Miercoles"}, {en: "Thursday", es: "Jueves"}, {en: "Friday", es: "Viernes"}, {en: "Saturday", es: "Sabado"}, {en: "Sunday", es: "Domingo"}]

export function VendorOpeningHours({vendor: {id, openingHours}}) {

  const { getTranslation } = useContext(IntlContext)
  const [editVendor] = useMutation(EDIT_VENDOR_MUTATION)

  async function changeAvailibility(day, value){
    const alert = toast.loading()
    let _openingHours = openingHours.map((openingHour) => {
      let { __typename, ...rest } = openingHour

      return Object.assign({}, {...rest})
    })
    _openingHours[day].isOpen = value

    try {
      await editVendor({variables: {id: id, data: {openingHours: _openingHours}}})
      return alert.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => alert.error(x.message))
    }
  }

  async function changeTime(day, key, data){
    const alert = toast.loading()
    let _openingHours = openingHours.map((openingHour) => {
      let { __typename, ...rest } = openingHour

      return Object.assign({}, {...rest})
    })
    _openingHours[day][key] = (data.hours + data.mins).toString()

    try {
      await editVendor({variables: {id: id, data: {openingHours: _openingHours}}})
      return alert.success(getTranslation({id: "save-success"}))
    } catch (e) {
      e.graphQLErrors.map(x => alert.error(x.message))
    }
  }

  return(
    <div id="opening-hours">
      {openingHours.map((day, key) =>
        <div key={key} className="day">
          <div className="day-title">
            <LabeledCheckbox rounded id={`open-${key}`} checked={day.isOpen} onChange={(e) => changeAvailibility(key, e.target.checked)}>
              <IntlValue>{days[key]}</IntlValue>
            </LabeledCheckbox>
          </div>
          {day.isOpen ?
            <div className="hours-wrap">
              <HourPicker time={day.from || "0000"} placeholder={getTranslation({group: "opening-hours", id: "from"})} onChange={(data) => changeTime(key, "from", data)}/>
              <HourPicker time={day.to || "0000"} placeholder={getTranslation({group: "opening-hours", id: "to"})} onChange={(data) => changeTime(key, "to", data)}/>
            </div>
          :
            <div className="hours-wrap">
              <span className='closed'><IntlText group='opening-hours' id='closed' /></span>
            </div>
          }
        </div>
      )}
    </div>
  )
}
