import React from 'react'
import { Link } from 'react-router-dom'
import FontAwesome from 'react-fontawesome'

import { useAuth } from '../../hooks'
import { Button, IntlText } from '../../components'

import './style.css'
import { useQuery } from '@apollo/client'
import { VENDOR_QUERY } from '../../graphql'

const alerts = {
  suspended: {color: "error"},
  technicalBreak: {color: "error"},
  onVacation: {color: "error"},
  slow: {color: "warning"}
}

export function AlertBar() {

  
  const { user } = useAuth()
  const id = user?.role === "VENDOR_USER" ? user?.vendor : user?.id
  const { loading, data, error } = useQuery(VENDOR_QUERY, {variables: {id}})
  
  function getAlertType(){
    if (data?.vendor?.suspended) return "suspended"
    if (data?.vendor?.technicalBreak) return "technicalBreak"
    if (data?.vendor?.onVacation) return "onVacation"
    
    return false
  }
  
  if (loading || error) return null

  const alert = alerts[getAlertType()]

  if (alert) return(
    <div id="alert-bar" className={`alert-bar ${alert.color} active`}>
      <div className="icon"><FontAwesome name="exclamation-triangle" /></div>
      <div className="message">
        <div className="title"><IntlText group="alert-bar" id="warning" /></div>
        <IntlText group="alert-bar" id={getAlertType()} />
      </div>
      <Link to="/settings/operation-settings"><Button className="edit"><IntlText group="alert-bar" id="button"/></Button></Link>
    </div>
  )

  return null
}
