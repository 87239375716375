import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { EDIT_PRODUCT_MUTATION } from '../../../graphql'
import { ContextMenu, IntlText } from '../../../components'
import { PurchaseProducts, PurchaseTotals } from '../../../views'

import './style.css'

export function PurchaseCart({purchase, purchase: {products}}) {

  const [edit] = useState(false)
  const [cart, setCart] = useState(products)
  const [editProduct] = useMutation(EDIT_PRODUCT_MUTATION)

  useEffect(() => {
    setCart(products)
  }, [products])

  async function soldOut({product}){
    try {
      await editProduct({variables: {id: product.originalId, data: {disabled: true}}})
      toast.success(<IntlText id="edit-success" />)
    } catch (e) {
      toast.error(<IntlText id="edit-error" />)
    }
  }  

  return(
    <div id="purchase-cart">
      <PurchaseProducts products={cart}>
        {({product}) => 
          <ContextMenu.Menu id={product.product.originalId} >
            <ContextMenu.Link icon="edit" to={`/products/${product.product.originalId}`}><IntlText id="edit" /></ContextMenu.Link>
            <ContextMenu.Button icon="times" onClick={() => soldOut(product)}><IntlText group="purchase-products" id="sold-out" /></ContextMenu.Button>
          </ContextMenu.Menu>     
        }
      </PurchaseProducts>
      {!edit &&
        <PurchaseTotals purchase={purchase} />      
      }     
    </div>
  )
}