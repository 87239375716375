import { toast } from 'react-toastify'

function loading(string){
    const toastID = toast.loading(string || "Please wait...", {autoClose: 3000})

    function success(_string){
        toast.update(toastID, { render: _string, type: "success", isLoading: false, autoClose: 3000 })
    }

    function error(_string){
        toast.update(toastID, { render: _string, type: "error", isLoading: false, autoClose: 3000 })
    }

    function apolloError(error){
        if (error.graphQLErrors?.length > 0){
            error.graphQLErrors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: 3000 })) 
        } else if(error.networkError?.result?.errors) {
            error.networkError.result.errors.map(x => toast.update(toastID, { render: x.message, type: "error", isLoading: false, autoClose: 3000 })) 
        } else {
            toast.update(toastID, { render: error.message, type: "error", isLoading: false, autoClose: 3000 })
        }        
    }    

    return {
        toastID,
        success,
        error,
        apolloError
    }
}

const _toast = {
    ...toast,
    loading
}

export default _toast
