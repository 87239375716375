import React, { useContext } from 'react'
import moment from 'moment'
import { useApolloClient } from '@apollo/client'

import { IntlContext } from '../../contexts'
import { vendorStat } from '../../helpers'
import { SERVICE_FEE_PROFORMA_INVOICE , PURCHASE_QUERY, PRODUCT_QUERY, CATEGORY_QUERY, PROMOTION, VENDOR_STAT } from '../../graphql'
import { Breadcrumbs as UIBreadcrumbs, IntlValue } from '../../components'

export function Breadcrumbs() {

  const client = useApolloClient()
  const { getTranslation } = useContext(IntlContext)
  const breadcrumbs = {
    label: getTranslation({group: "breadcrumbs", id: "home"}),
    "aviso-de-privacidad-afiliado-comercial": {
      label: getTranslation({group: "breadcrumbs", id: "privacy-policy"})
    },
    "become-exclusive": {
      label: getTranslation({group: "breadcrumbs", id: "become-exclusive"})
    },
    "terminos-y-condiciones-afiliado-comercial": {
      label: getTranslation({group: "breadcrumbs", id: "terms-of-service"})
    },
    transactions: {
      label: getTranslation({group: "breadcrumbs", id: "transactions"})
    },
    fallback: {
      label: async ({p, link, _routes, key, setRoutes}) => {
        try {
          const {data:{ purchase }} = await client.query({
            query: PURCHASE_QUERY,
            variables: {
              id: p
            },
          })

          let label = `${purchase.customer.name} (${moment(purchase.createdAt).format("YYYY-MM-DD")})`

          setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
        } catch (e) {
          console.log("Breadcrumb error", e);
        }
      }
    },    
    invoices: {
      label: getTranslation({group: "breadcrumbs", id: "invoices"}),
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ serviceFeeProformaInvoice }} = await client.query({
              query: SERVICE_FEE_PROFORMA_INVOICE,
              variables: {
                id: p
              },
            })
  
            let label = `${serviceFeeProformaInvoice.reference}`
  
            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        }
      },          
    },
    stats: {
      label: getTranslation({group: "breadcrumbs", id: "stats"}),  
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data} = await client.query({
              query: VENDOR_STAT,
              variables: {
                id: p
              },
            })

            const label = `${moment(data?.start).format("YYYY")}. ${vendorStat.getLabel(data?.vendorStat)}`

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        },
      }      
    },
    products: {
      label: getTranslation({group: "breadcrumbs", id: "products"}),
      new: {
        label: getTranslation({group: "breadcrumbs", id: "new-product"})
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ product }} = await client.query({
              query: PRODUCT_QUERY,
              variables: {
                id: p
              },
            })

            let label = product ? <IntlValue value={product.name} /> : getTranslation({group: "breadcrumbs", id: "not-found"})

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        },
        availibility: {
          label: getTranslation({group: "breadcrumbs", id: "product-availibility"})
        },
        addons: {
          label: getTranslation({group: "breadcrumbs", id: "product-options-extras"})
        },
        tags: {
          label: getTranslation({group: "breadcrumbs", id: "product-tags"})
        },
        media: {
          label: getTranslation({group: "breadcrumbs", id: "product-media"})
        },
      }
    },
    categories: {
      label: getTranslation({group: "breadcrumbs", id: "categories"}),
      new: {
        label: getTranslation({group: "breadcrumbs", id: "new-category"})
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ category }} = await client.query({
              query: CATEGORY_QUERY,
              variables: {
                id: p
              },
            })

            let label = <IntlValue value={category.name} />

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        }
      }
    },
    addons: {
      label: getTranslation({group: "breadcrumbs", id: "product-options-extras"}),
      options: {
        label: getTranslation({group: "breadcrumbs", id: "product-options"})
      }
    },     
    orders: {
      label: getTranslation({group: "breadcrumbs", id: "orders"}),
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ purchase }} = await client.query({
              query: PURCHASE_QUERY,
              variables: {
                id: p
              },
            })

            let label = `${purchase.customer.name} (${moment(purchase.createdAt).format("YYYY-MM-DD")})`

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        }
      }
    },
    help: {
      label: getTranslation({group: "breadcrumbs", id: "help"}),
      "get-started": {
        label: getTranslation({group: "breadcrumbs", id: "get-started"})
      },
      "purchases": {
        label: getTranslation({group: "breadcrumbs", id: "help-purchases"})
      },
      "terms": {
        label: getTranslation({group: "breadcrumbs", id: "terms"})
      },
      "delivery-zone": {
        label: getTranslation({group: "breadcrumbs", id: "delivery-zone"})
      },
    },
    packages: {
      label: getTranslation({group: "breadcrumbs", id: "packages"}),
      "basic": {
        label: getTranslation({group: "breadcrumbs", id: "packages-basic"})
      },
      "featured": {
        label: getTranslation({group: "breadcrumbs", id: "packages-featured"})
      }
    },
    feedbacks: {
      label: getTranslation({group: "breadcrumbs", id: "feedbacks"})
    },
    balance: {
      label: getTranslation({group: "breadcrumbs", id: "balance"}),
    },
    promotions: {
      label: getTranslation({group: "breadcrumbs", id: "promotions"}),
      new: {
        label: getTranslation({group: "breadcrumbs", id: "new-promotion"})
      },
      fallback: {
        label: async ({p, link, _routes, key, setRoutes}) => {
          try {
            const {data:{ promotion }} = await client.query({
              query: PROMOTION,
              variables: {
                id: p
              },
            })

            let label = promotion ? promotion.name : getTranslation({group: "breadcrumbs", id: "not-found"})

            setRoutes(Object.assign({[p]: {url: link, value: label, rank: key}}, _routes))
          } catch (e) {
            console.log("Breadcrumb error", e);
          }
        },
      }
    },
    marketing: {
      label: getTranslation({group: "marketing", id: "marketing"}),
      photoshoot: {
        label: getTranslation({group: "photoshoot", id: "photoshoot"})
      },
      "brand-identity": {
        label: getTranslation({group: "marketing", id: "brand-identity"})
      },
      "food-magazin": {
        label: getTranslation({group: "food-magazin", id: "food-magazin"}),
      },
    },
    settings: {
      label: getTranslation({group: "breadcrumbs", id: "settings"}),
      contact: {
        label: getTranslation({group: "breadcrumbs", id: "settings-contact"}),
      },
      invoicing: {
        label: getTranslation({group: "breadcrumbs", id: "settings-invoicing"}),
      },
      "operation-settings": {
        label: getTranslation({group: "breadcrumbs", id: "settings-operation"}),
      },
      location: {
        label: getTranslation({group: "breadcrumbs", id: "settings-location"}),
      },
      "opening-hours": {
        label: getTranslation({group: "breadcrumbs", id: "settings-opening-hours"}),
      },
      payment: {
        label: getTranslation({group: "breadcrumbs", id: "settings-payment"}),
      },
      delivery: {
        label: getTranslation({group: "breadcrumbs", id: "settings-delivery"}),
      },
      "product-types": {
        label: getTranslation({group: "breadcrumbs", id: "settings-product-types"}),
      },
      media: {
        label: getTranslation({group: "breadcrumbs", id: "settings-media"}),
      }      
    }
  }

  return <UIBreadcrumbs breadcrumbs={breadcrumbs}/>
}
