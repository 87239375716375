import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { PURCHASE_QUERY } from '../../graphql'
import { Loader, IntlText, Title, Grids } from '../../components'
import { PurchaseDetails, PurchaseStatus, PurchaseNotifications, PurchaseCart, PurchaseFeedback } from '../../views'
import { NotFound } from '../NotFound'

export function Purchase() {

  const { id } = useParams()
  const { data, loading, error } = useQuery(PURCHASE_QUERY, { variables: {id} })

  if (loading) return <Loader theme="main"/>
  if (error) return <NotFound />

  const { purchase } = data

  return(
    <div id="purchase" className="container">
      <div className="row padding">
        <Grids.Grids wrapped>
          <Grids.Grid>
            <Title tag="h3"><IntlText group='purchase' id='details' /></Title>
            <PurchaseDetails purchase={purchase} />
          </Grids.Grid>
          <Grids.Grid>
            <Title tag="h3"><IntlText group='purchase' id='products' /></Title>
            <PurchaseCart purchase={purchase} />
          </Grids.Grid>
          <Grids.Grid>
            <Title tag="h3"><IntlText group='purchase' id='instructions' /></Title>
            <PurchaseNotifications purchase={purchase} />
          </Grids.Grid>
          <Grids.Grid>
            <Title tag="h3"><IntlText group='purchase' id='status' /></Title>
            <PurchaseStatus purchase={purchase} />
          </Grids.Grid>
          {["UNPROCESSED", "COMPLETED"].includes(purchase.status) &&
            <Grids.Grid>
              <Title tag="h3"><IntlText group='purchase' id='feedback' /></Title>
              <PurchaseFeedback purchase={purchase} />
            </Grids.Grid>
          }
        </Grids.Grids>
      </div>
    </div>
  )
}
