import { gql }from '@apollo/client'

import { PURCHASE_PRICE_FRAGMENT, PURCHASE_CUSTOMER_FRAGMENT, PURCHASE_ADDRESS_FRAGMENT, PURCHASE_VENDOR_FRAGMENT, PURCHASE_PRODUCT_FRAGMENT } from './'

export const PURCHASE_FRAGMENT = gql`
  fragment PURCHASE_FRAGMENT on Purchase {
    id
    slug
    customerNote
    customer{
      ...PURCHASE_CUSTOMER_FRAGMENT
    }
    address{
      ...PURCHASE_ADDRESS_FRAGMENT
    }
    vendor{
      ...PURCHASE_VENDOR_FRAGMENT
    }
    products{
      ...PURCHASE_PRODUCT_FRAGMENT
    }
    price{
      ...PURCHASE_PRICE_FRAGMENT
    }
    rider{
      originalId
      slug
      color
      name
    }
    feedback{
      id
      productsQuantitative
      productsQualitative
      productsVoided
    }
    status
    sendUtensils
    paymentStatus
    payment
    preparationTime
    completedIn
    problem
    riderConfirmed
    arrivedAtVendor    
    timestamps{
      paymentProcessed
      processed
      vendorConfirmed
      assignedToRider
      riderConfirmed
      readyForCollection
      arrivedAtVendor
      collected
      arrivedAtClient
      completed
      deliveryFailed
      cancelled
      rejected
    }
    createdAt
  }
  ${PURCHASE_CUSTOMER_FRAGMENT}
  ${PURCHASE_ADDRESS_FRAGMENT}
  ${PURCHASE_VENDOR_FRAGMENT}
  ${PURCHASE_PRODUCT_FRAGMENT}
  ${PURCHASE_PRICE_FRAGMENT}
`
