import React, { useEffect, useContext } from 'react'

import { ModalContext } from '../../contexts'
import history from '../../history'
import { Skeleton, MediaQuery } from '../../components'
import { LeftMenu, Header, Breadcrumbs, Footer } from '../../views'

export function Wrapper({children, loading}) {

  const { closeModal } = useContext(ModalContext)

  useEffect(() => {
    history.listen(() => {
      closeModal()
    })
  })

  return(
    <div id="app">
      <LeftMenu />
      <div id="main">
        <Header>
          <MediaQuery min={800}>
            <Breadcrumbs />
          </MediaQuery>          
        </Header>
        <MediaQuery max={800}>
          <div className="row padding">
            <Breadcrumbs />
          </div>
        </MediaQuery>             
        {loading ?
          <div id="materials" className="container">
            <div className="row padding">        
              <Skeleton.Skeleton>
                <Skeleton.Row>
                  <Skeleton.Col>
                    <Skeleton.Row>
                      <Skeleton.Image />
                      <Skeleton.Col>
                        <Skeleton.Line short bottom />
                        <Skeleton.Line short bottom />
                      </Skeleton.Col>
                    </Skeleton.Row>
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />
                    <Skeleton.Line />                

                  </Skeleton.Col>
                </Skeleton.Row>
              </Skeleton.Skeleton>                 
            </div>
          </div>     
        :
          children
        }
        <Footer />
      </div>
    </div>
  )
}
